import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useContext } from 'react';
import { AppContext } from '@/contexts/app.context';
import { NavbarKey } from '@types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const NavigationBar: React.FC = () => {
  const { currentNavbarKey } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div>
      <ul className="flex xl:space-x-4 2xl:space-x-6 3xl:space-x-8 bg-black bg-opacity-80 rounded-full items-center text-sm lg:text-xs font-medium">
        <li
          className={`${
            currentNavbarKey === NavbarKey.BROWSE
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/">{t('browse')}</Link>
        </li>
        <li
          className={`${
            currentNavbarKey === NavbarKey.MOVIES
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/movies">{t('movies')}</Link>
        </li>
        <li
          className={`${
            currentNavbarKey === NavbarKey.TV_SHOW
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/tv_show">{t('tvShow')}</Link>
        </li>
        <li
          className={`${
            currentNavbarKey === NavbarKey.CHANNELS
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/channels">{t('channels')}</Link>
        </li>
        <li
          className={`${
            currentNavbarKey === NavbarKey.MUSIC
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/remezcla">{t('music')}</Link>
        </li>
        <li className="px-6 cursor-pointer">
          <Link href="/search">
            <SearchIcon className="text-white w-5 h-5" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
